import Layout from '@/components/Layout';
import PageHeader from '@/components/PageHeader';
import { useAudit, useTable } from '@/hooks';
import { useSession } from '@/store';
import { formatISODate, formatMoney } from '@/utils';
import {
  Avatar,
  Button,
  Descriptions,
  Divider,
  Drawer,
  Modal,
  notification,
  Popconfirm,
  Space,
  Table,
  Typography,
} from 'antd';
import {
  approveBrandSignupRequest,
  rejectBrandSignupRequest,
} from '@/services/brand.service';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ExternalLink } from 'lucide-react';

type SignupRequest = {
  id: string;
  brand: {
    id: string;
    name: string;
    logoUrl: string;
  };
  paymentPlan: {
    id: string;
    name: string;
    isActive: boolean;
    monthlyPrice: number;
    extraPerSubmissionPrice: number;
    subscription: {
      amount: number;
      trialRebate: number;
      subtotal: number;
    };
    oneTime: {
      amount: number;
      flyer: number;
      tax: number;
      subtotal: number;
    };
  };
  clientUser: {
    id: string;
    email: string;
    fullName: string;
  };
  status: string;
  clientUserId: string;
  brandId: string;
  dealerUpload: {
    fileName: string;
    data: Array<{
      name: string;
      isNewRecord: boolean;
      totalUsers: number;
      address: {
        number: string;
        street: string;
        city: string;
        state: string;
        country: string;
        countryCode: string;
        zip: string;
        formattedAddress: string;
        lat: number;
        lng: number;
      };
    }>;
  };
  campaign: {
    id: string;
    type: string;
    name: string;
    timeZone: string;
    endDate: string;
    finishCriteria: Array<string>;
    reach: {
      users: number;
      dealers: {
        total: number;
        inEndvr: number;
      };
    };
  };
  missions: Array<{
    id: string;
    title: string;
    subtitle: string;
    imageUrl: string;
    imageArgs: {
      fileId: string;
      versionId: string;
    };
    bundleMissionArgs: {
      type: string;
      displayLeaderboard: boolean;
      rankLeadersBasedOn: string;
      displayLeadersByUserInfo: boolean;
      displayLeadersByStoreInfo: boolean;
      displayLeadersByNumberSubmissions: boolean;
      displayLeadersByTotalSalesAmount: boolean;
      rulesAndRegulations: {
        useCustom: boolean;
        customUrl: string;
      };
    };
    contentItems: Array<any>;
    items: Array<{
      id: string;
      type: string;
      title: string;
      order: number;
    }>;
  }>;
  rewards: Array<{
    id: string;
    type: string;
    amount: number;
    budget?: number;
    maxPerUser: number;
    dynamicActive?: boolean;
    scope?: string;
    missionId: any;
    isUnlimited?: boolean;
    description?: string;
    title?: string;
    completionsToUnlock?: number;
    linkToShop?: string;
    noFinePrint?: boolean;
    codes?: Array<string>;
  }>;
  createdAt: string;
  updatedAt: string;
};

const SignupRequestPage = () => {
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState<SignupRequest[]>([]);
  const [status, setStatus] = useState('pending');
  const [filter, setFilter] = useState('');

  const session = useSession();

  const [previewModal, setPreviewModal] = useState<{
    isOpen: boolean;
    data: SignupRequest | null;
  }>({
    isOpen: false,
    data: null,
  });

  const { tableProps, refresh } = useTable<SignupRequest>({
    service: 'brand/read',
    path: `admin/signup-request/list`,
    params: {
      status,
      filters: {
        text: filter,
      },
    },
    defaultSort: {
      field: 'createdAt',
      order: 'descend',
    },
  });

  const audit = useAudit({
    resourceName: 'brand-signup-request-list',
  });

  useEffect(() => {
    audit.onAccess();
  }, []);

  const onApprove = async (id: string) => {
    if (!id) return;

    const { status } = await approveBrandSignupRequest(id);

    if (status) {
      refresh();
      notification.success({
        message: 'Request approved successfully',
        placement: 'bottomRight',
        duration: 2,
      });
    } else {
      notification.error({
        message: 'Failed to approve request',
        placement: 'bottomRight',
        duration: 2,
      });
    }
  };

  const onReject = async (id: string) => {
    if (!id) return;

    const { status } = await rejectBrandSignupRequest(id);

    if (status) {
      refresh();
      notification.success({
        message: 'Request rejected successfully',
        placement: 'bottomRight',
        duration: 2,
      });
    } else {
      notification.error({
        message: 'Failed to reject request',
        placement: 'bottomRight',
        duration: 2,
      });
    }
  };

  return (
    <>
      <Layout>
        <PageHeader noBackButton title="Brand Signup Requests" />

        <Space>
          <Button
            type={status === 'pending' ? 'primary' : 'default'}
            onClick={() => setStatus('pending')}
          >
            Pending
          </Button>
          <Button
            type={status === 'approved' ? 'primary' : 'default'}
            onClick={() => setStatus('approved')}
          >
            Approved
          </Button>
          <Button
            type={status === 'rejected' ? 'primary' : 'default'}
            onClick={() => setStatus('rejected')}
          >
            Rejected
          </Button>

          <Button
            type={status === 'failed' ? 'primary' : 'default'}
            onClick={() => setStatus('failed')}
          >
            Processing
          </Button>
        </Space>
        <br />

        <div>
          <Table {...tableProps} rowKey={'id'}>
            <Table.Column
              title="Brand"
              dataIndex="brand.name"
              render={(_, record: SignupRequest) => (
                <Space>
                  <Avatar size={48} src={record.brand.logoUrl} shape="square" />

                  <Space.Compact direction={'vertical'}>
                    <Typography.Text strong>
                      {record.brand.name}
                    </Typography.Text>
                  </Space.Compact>
                </Space>
              )}
            />

            <Table.Column
              title="Requester"
              dataIndex="paymentPlan.name"
              render={(_, record: SignupRequest) => (
                <Space.Compact direction={'vertical'}>
                  <Typography.Text strong>
                    {record.clientUser.fullName || '(name not set)'}
                  </Typography.Text>
                  <Typography.Text type={'secondary'}>
                    {record.clientUser.email || '(email not set)'}
                  </Typography.Text>
                </Space.Compact>
              )}
            />

            <Table.Column
              title="Payment Plan"
              dataIndex="paymentPlan.name"
              render={(_, record: SignupRequest) => (
                <Space.Compact direction={'vertical'}>
                  <Typography.Text strong>
                    {record.paymentPlan.name}
                  </Typography.Text>
                  <Typography.Text type={'secondary'}>
                    One Time Payment:{' '}
                    {formatMoney(record.paymentPlan?.oneTime?.amount)}
                  </Typography.Text>
                  <Typography.Text type={'secondary'}>
                    Subscription Price:{' '}
                    {formatMoney(record.paymentPlan?.subscription?.amount)}
                  </Typography.Text>
                </Space.Compact>
              )}
            />

            {session.hasPermission('brands_signup_requests.edit') && (
              <Table.Column
                title=""
                dataIndex="actions"
                key="actions"
                align="right"
                render={(text: any, record: SignupRequest, index: number) => {
                  return (
                    <Space>
                      <Button
                        onClick={() => {
                          setPreviewModal({
                            isOpen: true,
                            data: record,
                          });
                        }}
                      >
                        Details
                      </Button>
                      {record.status === 'pending' && (
                        <>
                          <Popconfirm
                            title="Are you sure you want to approve this request?"
                            description="This action cannot be undone."
                            onConfirm={() => onApprove(record.id)}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button type="primary">Approve</Button>
                          </Popconfirm>

                          <Popconfirm
                            title="Are you sure you want to reject this request?"
                            description="This action cannot be undone."
                            onConfirm={() => onReject(record.id)}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button danger>Reject</Button>
                          </Popconfirm>
                        </>
                      )}
                    </Space>
                  );
                }}
              />
            )}
          </Table>
        </div>
      </Layout>
      <Drawer
        title="Details"
        open={previewModal.isOpen}
        onClose={() => setPreviewModal({ isOpen: false, data: null })}
        destroyOnClose
        width={800}
        extra={
          <Space>
            {previewModal.data?.status === 'pending' && (
              <Popconfirm
                title="Are you sure you want to approve this request?"
                description="This action cannot be undone."
                onConfirm={() => onApprove(previewModal.data?.id || '')}
                okText="Yes"
                cancelText="No"
              >
                <Button type="primary">Approve</Button>
              </Popconfirm>
            )}
            {previewModal.data?.status === 'pending' && (
              <Popconfirm
                title="Are you sure you want to reject this request?"
                description="This action cannot be undone."
                onConfirm={() => onReject(previewModal.data?.id || '')}
                okText="Yes"
                cancelText="No"
              >
                <Button danger>Reject</Button>
              </Popconfirm>
            )}
          </Space>
        }
      >
        {/* <Divider orientation="left" orientationMargin="0">
          Brand
        </Divider> */}

        <Descriptions title="Brand" column={2}>
          <Descriptions.Item label="ID">
            <Typography.Text copyable>
              {previewModal.data?.brand.id}
            </Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="Name">
            <Link
              to={`/brand/${previewModal.data?.brand.id}/manage`}
              target="_blank"
              rel="noreferrer"
            >
              <Space>
                <span>{previewModal.data?.brand.name}</span>
                <ExternalLink size={14} />
              </Space>
            </Link>
          </Descriptions.Item>
        </Descriptions>

        <br />

        <Descriptions title="Requester" column={2}>
          <Descriptions.Item label="ID">
            <Typography.Text copyable>
              {previewModal.data?.clientUser.id}
            </Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="Name">
            <Typography.Text>
              {previewModal.data?.clientUser.fullName || '(name not set)'}
            </Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="Email">
            <Typography.Text copyable>
              {previewModal.data?.clientUser.email}
            </Typography.Text>
          </Descriptions.Item>
        </Descriptions>

        <br />

        <Descriptions title="Payment Plan" column={2}>
          <Descriptions.Item label="Name">
            <Typography.Text>
              {previewModal.data?.paymentPlan.name}
            </Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="One Time Payment">
            <Typography.Text>
              {formatMoney(
                previewModal.data?.paymentPlan?.oneTime?.amount || 0,
              )}
            </Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="Subscription Price">
            <Typography.Text>
              {formatMoney(
                previewModal.data?.paymentPlan?.subscription?.amount || 0,
              )}
            </Typography.Text>
          </Descriptions.Item>
        </Descriptions>

        <br />

        <Descriptions title="Campaign" column={1}>
          <Descriptions.Item label="Name">
            <Typography.Text>
              {previewModal.data?.campaign.name}
            </Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="End Date">
            <Typography.Text>
              {formatISODate(previewModal.data?.campaign.endDate)} (
              {previewModal.data?.campaign.timeZone})
            </Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="Type">
            <Typography.Text>
              {previewModal.data?.campaign.type}
            </Typography.Text>
          </Descriptions.Item>
        </Descriptions>
      </Drawer>
    </>
  );
};

export default SignupRequestPage;
