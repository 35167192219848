export type MobileUser = {
  id: string;
  fullName: string;
  email: string;
  isActive: boolean;
  isDeleted: boolean;
  isConfirmed: boolean;
  avatarUrl: string;
  storeId: string;
  referralCode: string;
  store: {
    id: string;
    name: string;
    logoUrl: string;
    address: string;
  };
  createdAt: string;
  phoneNumber: string;
  deletedAt?: string;
  earnings?: number;
  pendingEarnings?: number;
  lastActivityAt?: string;
  role: string;
  riskLevel: string;
  isWhitelisted: boolean;
  isBanned: boolean;
  whitelistDevicesTotal: number;
  bannedDevicesTotal: number;
  poeStatus: string;
  poeRejectedReason: string;
  pendingPoeSubmissionUrl: string;
  poeAnalysisStatus?: string;
  poeAnalysis?: any;
  referralLink: string;
};

export type Store = {
  id: string;
  address: {
    street: string;
    state: string;
    country: string;
    countryCode: string;
    zip: string;
    formattedAddress: string;
    lat: number;
    lng: number;
  };
  brandOwnerId: any;
  connectedAt: string;
  connectedBrands: {
    id: string;
    name: string;
    status: string;
  }[];
  createdAt: string;
  currency: string;
  industries: string[];
  isExclusive: boolean;
  isLocked: boolean;
  isHidden: boolean;
  isPrivate: boolean;
  location: {
    type: string;
    coordinates: number[];
  };
  logoUrl: string;
  name: string;
  searchKey: string;
  shopUrl: string;
  storeIndustries: {
    id: string;
    name: string;
  }[];
  totalApprovedBrands: number;
  totalBlockedBrands: number;
  totalEarnings: number;
  totalPendingBrands: number;
  totalPendingUsers: number;
  totalRewardsPaid: number;
  totalSales: number;
  totalUsers: number;
  updatedAt: string;
};

export type Brand = {
  id: string;
  name: string;
  logoUrl: string;
  settingsPlan: string;
  currency: string;
  countries: string[];
  isHidden: false;
  isInactive: boolean;
  isManaged: false;
  segment: string;
  language: string;
  smartReviewEnabled: false;
  smartReviewFrequency: string;
  smartReviewPrice: number;
  prizePercentage: number;
  industries: {
    id: string;
    name: string;
  }[];
  wallet: {
    balance: number;
    pending: number;
  };
  campaignDeposited: {
    total: number;
    earned: number;
    left: number;
  };
  createdAt: number;
  updatedAt: number;
};

export type UserStoreRequest = {
  id: string;
  storeId: string;
  userId: string;
  brandId: string;
  classification: string;
  classificationReasons: string[];
  createdAt: string;
  devices: {
    appVersion: string;
    createdAt: string;
    language: string;
    model: string;
    os: string;
    osVersion: string;
    timezone: string;
    token: string;
    updatedAt: string;
    id: string;
  }[];
  status: string;
  store: {
    name: string;
    logoUrl: string;
    createdAt: string;
    address: {
      number: string;
      street: string;
      city: string;
      state: string;
      country: string;
      countryCode: string;
      zip: string;
      formattedAddress: string;
      lat: number;
      lng: number;
    };
    isPrivate: boolean;
    isExclusive: boolean;
    isHidden: boolean;
    id: string;
    timezone: string;
    timezoneOffset: number;
  };
  updatedAt: string;
  user: {
    fullName: string;
    avatarUrl: string;
    email: string;
    isConfirmed: boolean;
    id: string;
  };
};

export type GenericObject = object & {
  [key: string]: any;
};

export type HiveProductAnalysis = {
  description: string;
  sku: string;
  quantity: number;
  unitPrice: number;
  productCategory?: string;
  targetAudience?: string;
  probableBrand?: string;
  productMarketAudience?: string;
  productDescription?: string;
};

export type HiveReceiptAnalysis = {
  storeName: string;
  receiptDate: string;
  receiptId: string;
  address: {
    street: string;
    city: string;
    state: string;
    zipCode: string;
  };
  contact: {
    phone: string;
    website: string;
  };
  salesTicket: string;
  salesPerson: string;
  transactionDate: string;
  products: Array<HiveProductAnalysis>;
  totalAmount: number;
  currency: string;
  tax: number;
  netTotal: number;
  paymentMethod: string;
  tenderAmount: string;
  customerNumber: string;
  postProcessedProducts: Array<HiveProductAnalysis>;
};

export type SalesIncentiveCompletion = {
  id: string;
  answers: Array<{
    missionItemId: string;
    answer: string;
    type: string;
    createdAt: string;
  }>;
  approvedAt: any;
  brandId: string;
  bundle: {
    id: string;
    name: string;
    status: string;
    type: string;
    approvalNote: string;
    endDate: string;
    launchedAt: string;
  };
  bundleId: string;
  companyId: string;
  completedAt: string;
  analysisProcessor: 'hive' | 'azure' | 'verify';
  createdAt: string;
  earnings: number;
  currency: string;
  hasScan: boolean;
  insertedBy: string;
  analysis?: {
    state: string;
    processedAt: Date;
    data: any;
  };
  rawAnalysis: HiveReceiptAnalysis;
  mission: {
    id: string;
    title: string;
    imageUrl: string;
  };
  missionId: string;
  rejectedAt: any;
  rejectionArgs: {
    rejectBy: string;
    rejectText: string;
  };
  salesAmount: number;
  scanState: string;
  searchKey: string;
  searchType: string;
  status: string;
  scanProcessing: boolean;
  processing: boolean;
  store: {
    id: string;
    name: string;
    address: {
      lat: number;
      zip: string;
      lng: number;
      city: string;
      formattedAddress: string;
      street: string;
      country: string;
      state: string;
      countryCode: string;
    };
    currency: string;
  };
  storeId: string;
  updatedAt: string;
  user: {
    id: string;
    fullName: string;
    email: string;
    avatarUrl: string;
    isDeleted: boolean;
  };
  userId: string;
  userKpi: {
    missions: {
      total: number;
      pending: number;
      approved: number;
      rejected: number;
    };
    contestSubmissions: {
      total: number;
      pending: number;
      approved: number;
      rejected: number;
    };
    learnSubmissions: {
      total: number;
      correct: number;
      wrong: number;
    };
    earnedMoney: {
      general: {
        total: number;
        pending: number;
        approved: number;
      };
      mission: {
        total: number;
        pending: number;
        approved: number;
      };
      contest: {
        total: number;
        pending: number;
        approved: number;
      };
      learn: {
        total: number;
      };
    };
    totalSales: {
      total: number;
      pending: number;
      approved: number;
      rejected: number;
    };
    approvalPercentage: number;
    rejectionPercentage: number;
  };
  userWalletId: string;
  webUserId: any;
  brand: {
    id: string;
    name: string;
    logoUrl: string;
  };
  hubspotReferenceId: any;
};

export type ConsumerCompletion = {
  id: string;
  status: string;
  consumerId: string;
  uid: string;
  missionId: string;
  bundleId: string;
  brandId: string;
  answers: any[];
  earnings: number;
  currency: string;
  processing: boolean;
  consumerWalletId: string;
  scanProcessing: boolean;
  missionItems: any[];
  completedAt: Date;
  approvedAt?: Date;
  rejectedAt?: Date;
  rejectionArgs?: {
    rejectBy: string;
    rejectText: string;
  };
  scanState: string;
  hiveRequestStatus: string;
  hiveAttempts: number;
  feedback?: string;
  analysis?: {
    state: string;
    processedAt: Date;
    data: object;
  };
  rawAnalysis?: HiveReceiptAnalysis;
  mission: {
    id: string;
    title: string;
    type: string;
    imageUrl: string;
    consumerData: {
      productName: string;
      rewardName: string;
    };
  };
  bundle: {
    id: string;
    uid: string;
    name: string;
    type: string;
    status: string;
    createdAt: Date;
    launchDate: Date;
    endDate: Date;
    approvalNote: string;
  };
  consumer: {
    id: string;
    phoneNumber: string;
    fullName: string;
    email: string;
  };
  brand: {
    id: string;
    name: string;
    logoUrl: string;
  };
  createdAt: Date;
  updatedAt: Date;
};

export type ManagerSubmission = {
  _id: string;
  clientId: string;
  clientUserId: string;
  creatorFullName: string;
  creatorEmail: string;
  brandId: string;
  missionBrandName: string;
  missionBrandLogoUrl: string;
  campaignName: string;
  storeId: string;
  missionId: string;
  bundleId: string;
  creatorAvatarUrl: string;
  earnings: number;
  dateRange: {
    start: Date;
    end: Date;
  };
  storeLogoUrl: string;
  storeAddress: string;
  storeName: string;
  status: string;
  numberOfUnits: number;
  totalAmount: number;
  salesReport: string;
  users: Array<{
    id: string;
    percentage: number;
  }>;
  items: Array<{
    id: string;
    percentage: number;
  }>;
  createdAt: Date;
  updatedAt: Date;
  isValid: boolean;
  bundleBrandId: string;
  submittedAt: Date;
  approvedAt: Date;
  rejectedAt: Date;
  missionStatus: string;
};

export type DetailedManagerSubmission = {
  itemOptions: {
    id: string;
    title: string;
  }[];
  userOptions: {
    id: string;
    fullName: string;
    email: string;
    avatarUrl?: string;
    totalCompletions: number;
  }[];
  creator: {
    _id: string;
    fullName: string;
    email: string;
    password: string;
    avatarUrl?: string;
    clientId: string;
    role: string;
    isConfirmed: boolean;
    createdAt: string;
    updatedAt: string;
    language: string;
    timezone: string;
  };
  bundle: {
    _id: string;
    brandId: string;
    status: string;
    createdByUserId: string;
    type: string;
    completionProgress: number;
    name: string;
    createdAt: string;
    updatedAt: string;
    endDate: string;
    finishedAt: string;
    launchedAt: string;
    launchedByUserId: string;
    scope: {
      type: string;
      pks: string[];
    };
    timeZone: string;
    finishedByUserId: string;
    finishedReason: string;
    isFinishNotified: boolean;
    sync: {
      executionId: number;
      executedAt: number;
    };
  };
  brand: {
    _id: string;
    ownerId: string;
    name: string;
    isHidden: boolean;
    hasNotified: string;
    updatedAt: string;
    createdAt: string;
    logoUrl: string;
    activeMissionCompletionNotification: boolean;
    eachMissionCompletionNotification: number;
    hasUpdate: boolean;
    kpis: {
      pendingUsers: number;
      stores: {
        approved: number;
        pending: number;
      };
      users: number;
      completions: {
        pending: number;
        approved: number;
        rejected: number;
        total: number;
      };
      campaigns: {
        live: number;
        finished: number;
      };
    };
    kpi: {
      completions: {
        total: number;
        pending: number;
        approved: number;
        rejected: number;
      };
      users: number;
      pendingUsers: number;
      stores: {
        approved: number;
        pending: number;
      };
      campaigns: {
        live: number;
        finished: number;
      };
    };
    description: string;
    coverUrl: string;
    settings: {
      smartReview: {
        service: string;
      };
      smartReviewAnalysis: {
        provider: string;
      };
    };
  };
  mission: {
    _id: string;
    brandId: string;
    title: string;
    description: string;
    status: string;
    type: string;
    imageUrl: string;
    contentItems: unknown[];
    isCompleteFill: boolean;
    createdByUserId: string;
    createdAt: string;
    updatedAt: string;
    archivedAt: string;
    imageArgs: {
      fileId: string;
      versionId: string;
    };
    launchedAt: string;
    version: string;
    isDeleted: boolean;
  };
  store: {
    _id: string;
    name: string;
    isHidden: boolean;
    isExclusive: boolean;
    isPrivate: boolean;
    isDraft: boolean;
    createdAt: string;
    updatedAt: string;
    address: {
      number: string;
      street: string;
      city: string;
      state: string;
      country: string;
      countryCode: string;
      zip: string;
      formattedAddress: string;
      lat: number;
      lng: number;
    };
    keywords: string;
    location: {
      type: string;
      coordinates: number[];
    };
    nameKeywords: string;
    shopUrl: string;
    currency: string;
    kpis: {
      brands: number;
      pendingUsers: number;
      users: number;
      gifts: number;
    };
    brandOwnerId: string;
    kpi: {
      users: number;
      pendingUsers: number;
      brands: number;
    };
    isLocked: boolean;
  };
  rewardMoney: number;
  form: {
    _id: string;
    clientId: string;
    clientUserId: string;
    brandId: string;
    bundleBrandId: string;
    storeId: string;
    missionId: string;
    bundleId: string;
    status: 'draft' | 'pending' | 'approved' | 'rejected' | 'expired';
    earnings: number;
    salesReportUrl: string;
    salesReportArgs: {
      fileType: string;
      fileName: string;
    };
    dateRange: {
      start: string;
      end: string;
    };
    numberOfUnits: number;
    totalAmount: number;
    users: {
      id: string;
      percentage: number;
      unitsSold: number;
      totalSales: number;
    }[];
    items: {
      id: string;
      percentage: number;
      unitsSold: number;
      totalSales: number;
    }[];
    isValid: boolean;
    createdAt: string;
    updatedAt: string;
    submittedAt: string;
    approvedAt: string;
    rejectedAt: string;
    rejectionArgs: {
      rejectBy: string;
      rejectText: string;
    };
  };
  rewards: {
    _id: string;
    type: string;
    key: string;
    brandId: string;
    bundleId: string;
    amount: number | null;
    completionsToEarn: number | null;
    budget: number | null;
    title: string;
    description: string;
    percentageDiscount: number;
    completionsToUnlock: string;
    linkToShop: string;
    uploadedCodes: {
      fileUrl: string;
      fileName: string;
      codes: string[];
    };
    noFinePrint: boolean;
    finePrint: string;
    createdAt: string;
    updatedAt: string;
    codes: {
      code: string;
      owned: boolean;
      ownedAt: string | null;
      rewardUserId: string | null;
    }[];
    kpi: {
      totalCodesUnlocked: number;
      remainingCodes: number;
    };
    endDate: string;
  }[];
  rulesAndRegulations: string;
  activeSections: {
    uploadSalesReport: boolean;
    enterSalesAmount: boolean;
    selectItemSold: boolean;
  };
  limitSubmissions: {
    storeSubmitted: number;
    maxPerStore: number;
  };
  availableBudget: number;
  totalRemainingBudget: number;
};

export type PoeAnalysisStatus = {
  id: string;
  fullName: string;
  email: string;
  avatarUrl: string;
  isActive: boolean;
  isConfirmed: boolean;
  storeId: string;
  referralCode: string;
  riskLevel: string;
  store: {
    id: string;
    name: string;
    logoUrl: string;
    address: string;
  };
  poeAnalysisStatus: string;
  lastPoeAnalysis: any;
  score: number;
  poeStatus: string;
  createdAt: Date;
  updatedAt: Date;
  isWhitelisted: boolean;
  phoneNumber: string;
  missionCompletionsApprovalScore: number;
};

export type WebUser = {
  id: string;
  fullName: string;
  avatarUrl: string;
  email: string;
  isConfirmed: boolean;
  isDeleted: boolean;
  language: string;
  role: string;
  brands: WebUserBrand[];
  createdAt: string;
  defaultProfile?: string;
  deletedAt?: string;
};

export type WebUserBrand = {
  profile:
    | 'Sales Rep'
    | 'No Access'
    | 'Owner'
    | 'Admin'
    | 'Master'
    | 'Viewer'
    | 'Store Manager'
    | 'Endvr Team'
    | string;
  id: string;
  profileId: string;
  name: string;
  logoUrl: string;
  createdAt: Date;
};

export const RoleDropdown = [
  {
    value: 'Sales Rep',
    label: 'Sales Rep',
  },
  {
    value: 'Admin',
    label: 'Admin',
  },
  // {
  //   value: 'Owner',
  //   label: 'Owner',
  // },
  {
    value: 'Store Manager',
    label: 'Store Manager',
  },
  {
    value: 'Viewer',
    label: 'Viewer',
  },
  {
    value: 'Endvr Team',
    label: 'Endvr Team',
  },
];

export type Group = {
  id: string;
  name: string;
  brandId: string;
  creatorId: string;
  createdAt: string;
  updatedAt: string;
  storesIds: string[];
};

export type CampaignListItem = {
  id: string;
  brandId: string;
  status: string;
  type: string;
  completionProgress: number;
  name: string;
  timeZone: string;
  brand: {
    id: string;
    name: string;
    logoUrl: string;
  };
  missions: Array<{
    isCompleteFill: boolean;
    imageUrl: string;
    totalSales: number;
    createdAt: {
      $date: string;
    };
    totalCompletions: number;
    id: string;
    type: string;
    title: string;
  }>;
  rewards: Array<{
    leftBudget: number;
    maxPerUser: number;
    budget: number;
    title: string;
    completionsToUnlock: number;
    id: string;
    totalCodes: number;
    percentageDiscount: number;
    availableCodes: number;
    earnedMoney: number;
    unlockedCodes: number;
    type: string;
    amount: number;
    isUnlimited: boolean;
  }>;
  scope: string;
  reach: {
    groups: number;
    stores: number;
    users: number;
  };
  createdAt: string;
  updatedAt: string;
  endDate: string;
  finishedAt: string;
  launchedAt: string;
};

export type MobilePayment = {
  type: string;
  fee: number;
  isActive: boolean;
  minValue: number;
};

export type MobilePayments = MobilePayment[];

export type IAddress = {
  formattedAddress?: string;
  street?: string;
  number?: string;
  zip?: string;
  city: string;
  state: string;
  country: string;
  countryCode: string;
  lat?: number;
  lng?: number;
  continent?: '';
  ip?: '';
};


