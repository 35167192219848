import Layout from '@/components/Layout';
import PageHeader from '@/components/PageHeader';
import { useAudit, useTable } from '@/hooks';
import { TableContainer } from '@/styles';
import { Brand } from '@/types';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Dropdown, Space, Switch, Table, Tag, Typography } from 'antd';
import { useEffect, useState } from 'react';
import ModalForm from './modal-form';
import { BrandPlan } from './types';
import { formatMoney } from '@/utils';
import { get, startCase } from 'lodash';
import { campaignTypeOptions, planTypeOptions } from './contants';
import { HiOutlineDotsHorizontal } from 'react-icons/hi';
import { updatePlanActiveStatus } from '@/services/brand.service';
import { FaTimesCircle, FaCheckCircle } from 'react-icons/fa';
import { MdEditSquare } from 'react-icons/md';

const PlanComponent = () => {
  const [filter, setFilter] = useState('');
  const [isOpenForm, setOpenForm] = useState(false);
  const [currentPlan, setCurrentPlan] = useState<BrandPlan | null>(null);

  const { tableProps, refresh } = useTable<Brand>({
    service: 'brand/read',
    path: `admin/plan/list`,
    params: {
      filters: {
        text: filter,
      },
    },
    defaultSort: {
      field: 'name.en',
      order: 'descend',
    },
  });

  const audit = useAudit({
    resourceName: 'brand-plan',
  });

  useEffect(() => {
    audit.onAccess();
  }, []);

  return (
    <Layout noCard={false}>
      <PageHeader noBackButton title="Brand Plans">
        <Button
          type="primary"
          onClick={() => {
            setCurrentPlan(null);
            setTimeout(() => setOpenForm(true), 0);
          }}
          icon={<PlusOutlined />}
        >
          Add Plan
        </Button>
      </PageHeader>

      <TableContainer>
        <Table {...tableProps} rowKey={'id'}>
          <Table.Column
            title="Plan Name"
            dataIndex="name"
            key="name"
            sorter
            render={(data) => {
              return (
                <Space.Compact direction={'vertical'}>
                  <Typography.Text>EN: {get(data, 'en', '-')}</Typography.Text>
                  <Typography.Text>FR: {get(data, 'fr', '-')}</Typography.Text>
                </Space.Compact>
              );
            }}
          />
          <Table.Column
            title="Plan Type"
            dataIndex="planType"
            key="planType"
            filters={planTypeOptions}
            render={(val) => startCase(val)}
          />
          <Table.Column
            title="Campaign Type"
            dataIndex="campaignType"
            key="campaignType"
            sorter
            filters={campaignTypeOptions}
            render={(val) => {
              if (!val) return '-';
              const row = campaignTypeOptions.find(
                (item: any) => item.value === val,
              );
              return row?.label;
            }}
          />

          <Table.Column
            title="Status"
            dataIndex="isActive"
            key="isActive"
            align="center"
            sorter
            filters={[
              {
                text: 'Active',
                value: true,
              },
              {
                text: 'Inactive',
                value: false,
              },
            ]}
            render={(text: any, record: BrandPlan, index: number) => {
              if (record.isActive) {
                return <Tag color="success">Active</Tag>;
              }

              return <Tag color="error">Inactive</Tag>;
            }}
          />
          <Table.Column
            title=""
            dataIndex="actions"
            key="actions"
            align="right"
            render={(text: any, record: BrandPlan, index: number) => {
              return (
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: 'edit',
                        label: 'Edit',
                        icon: <MdEditSquare size={20} />,
                        onClick: () => {
                          setCurrentPlan(record);
                          setOpenForm(true);
                        },
                      },
                      {
                        key: 'change-status',
                        label: record.isActive ? 'Deactivate' : 'Activate',
                        danger: record.isActive,
                        icon: !record.isActive ? (
                          <FaCheckCircle size={20} />
                        ) : (
                          <FaTimesCircle size={20} />
                        ),
                        onClick: async () => {
                          if (record.id) {
                            await updatePlanActiveStatus(
                              record.id,
                              !record.isActive,
                            );
                            refresh();
                          }
                        },
                      },
                    ],
                  }}
                >
                  <Button size={'small'}>
                    <HiOutlineDotsHorizontal size={20} />
                  </Button>
                </Dropdown>
              );
            }}
          />
        </Table>
      </TableContainer>
      <ModalForm
        isOpen={isOpenForm}
        onClose={setOpenForm}
        refrsh={refresh}
        data={currentPlan}
      />
    </Layout>
  );
};

export default PlanComponent;
