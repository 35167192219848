export const planTypeOptions = [
  { text: 'Basic', value: 'basic', label: 'Basic' },
  { text: 'Pro', value: 'pro', label: 'Pro' },
  { text: 'Custom', value: 'custom', label: 'Custom' },
];

export const campaignTypeOptions: any = [
  { text: 'Retail Ops', value: 'general', label: 'Retail Ops' },
  { text: 'Sales Incentive', value: 'contest', label: 'Sales Incentive' },
  { text: 'Digital Learning', value: 'learning', label: 'Digital Learning' },
  {
    text: 'Consumer Marketing',
    value: 'consumer',
    label: 'Consumer Marketing',
  },
];
