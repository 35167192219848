import {
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Row,
  Select,
  Switch,
} from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import { BrandPlan } from './types';
import { CustomSectionHeader } from './styles';
import { createBrandPlan, updateBrandPlan } from '@/services/brand.service';

import { campaignTypeOptions, planTypeOptions } from './contants';

type Args = {
  isOpen: boolean;
  onClose: (isOpen: boolean) => void;
  data?: BrandPlan | null;
  refrsh?: () => void;
};

const visibilityOptions = [
  { label: 'Allowed', value: 'allowed' },
  { label: 'Preview', value: 'preview' },
  { label: 'Blocked', value: 'blocked' },
];

const ModalForm: FC<Args> = ({ isOpen, onClose, refrsh, data }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<BrandPlan | null>(null);

  useEffect(() => {
    form.resetFields();

    setTimeout(() => {
      if (isOpen && data) {
        form.setFieldsValue(data);
        setFormData(data);
      }
    }, 0);
  }, [isOpen, data]);

  const onFinish = async (values: any) => {
    const fields = {
      ...values,
      isActive: values.isActive || false,
    };

    try {
      if (data?.id) {
        await updateBrandPlan(data.id, fields);
      } else {
        await createBrandPlan(fields);
      }

      notification.success({
        message: 'Success',
        description: 'Plan saved successfully',
      });
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Failed to create plan',
      });
    }

    refrsh?.();
    onClose(false);
  };

  useEffect(() => {
    if (formData?.unlimitedResources) {
      form.setFieldsValue({
        limitLiveCampaigns: -1,
        limitMissionsPerCampaigns: -1,
        limitReachStores: -1,
        limitSubmissions: -1,
        limitTotalSales: -1,
      });
    }
  }, [formData?.unlimitedResources]);

  return (
    <Modal
      title={
        data?.id ? `Edit Plan ${data?.name?.en || ''}` : 'Create a new plan'
      }
      open={isOpen}
      onOk={() => form.submit()}
      onCancel={() => onClose(false)}
      okButtonProps={{ disabled: false, loading }}
      okText={data?.id ? 'Save Plan' : 'Create Plan'}
      cancelButtonProps={{ disabled: false }}
      width={800}
    >
      <br />
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onValuesChange={(changedValues, allValues) => {
          setFormData((prev) => ({
            ...prev,
            ...allValues,
          }));
        }}
      >
        <Divider
          orientation="left"
          orientationMargin="0"
          style={{ margin: '4px 0 8px' }}
        >
          Plan Name
        </Divider>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              name={['name', 'en']}
              label="English"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name={['name', 'fr']}
              label="French"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Divider
          orientation="left"
          orientationMargin="0"
          style={{ margin: '4px 0 8px' }}
        >
          Description
        </Divider>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              name={['description', 'en']}
              label="English"
              rules={[{ required: true }]}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name={['description', 'fr']}
              label="French"
              rules={[{ required: true }]}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>

        <Divider
          orientation="left"
          orientationMargin="0"
          style={{ margin: '4px 0 8px' }}
        >
          Types
        </Divider>

        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Form.Item
              name="planType"
              label="Plan"
              rules={[{ required: true }]}
              layout="vertical"
            >
              <Select options={planTypeOptions} />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="campaignType"
              label="Campaign"
              rules={[{ required: true }]}
              layout="vertical"
            >
              <Select options={campaignTypeOptions} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Divider
              orientation="left"
              orientationMargin="0"
              style={{ margin: '4px 0 8px' }}
            >
              Pricing (USD)
            </Divider>
            <Form.Item
              name="monthlyPrice"
              label="Monthly"
              layout="horizontal"
              labelCol={{ span: 12 }}
            >
              <InputNumber min={1} />
            </Form.Item>
            <Form.Item
              name="extraPerSubmissionPrice"
              label="Extra Per Submission"
              layout="horizontal"
              labelCol={{ span: 12 }}
            >
              <InputNumber min={0} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <CustomSectionHeader>
              <Divider
                orientation="left"
                orientationMargin="0"
                style={{ margin: '4px 0 8px' }}
              >
                <span>Limits (Quantity)</span>
              </Divider>
              <Form.Item
                name="unlimitedResources"
                label="Unlimited"
                layout="horizontal"
                style={{ marginBottom: 0 }}
              >
                <Switch />
              </Form.Item>
            </CustomSectionHeader>

            <Form.Item
              name="limitLiveCampaigns"
              label="Live Campaigns"
              rules={[{ required: !formData?.unlimitedResources }]}
              layout="horizontal"
              labelCol={{ span: 12 }}
            >
              <InputNumber min={1} disabled={formData?.unlimitedResources} />
            </Form.Item>

            <Form.Item
              name="limitMissionsPerCampaigns"
              label="Missions Per Campaign"
              rules={[{ required: !formData?.unlimitedResources }]}
              layout="horizontal"
              labelCol={{ span: 12 }}
            >
              <InputNumber min={1} disabled={formData?.unlimitedResources} />
            </Form.Item>

            <Form.Item
              name="limitReachStores"
              label="Reach Stores"
              rules={[{ required: !formData?.unlimitedResources }]}
              layout="horizontal"
              labelCol={{ span: 12 }}
            >
              <InputNumber min={1} disabled={formData?.unlimitedResources} />
            </Form.Item>

            <Form.Item
              name="limitSubmissions"
              label="Collect Submissions"
              rules={[{ required: !formData?.unlimitedResources }]}
              layout="horizontal"
              labelCol={{ span: 12 }}
            >
              <InputNumber min={1} disabled={formData?.unlimitedResources} />
            </Form.Item>
            <Form.Item
              name="limitTotalSales"
              label="Total Sales Amount"
              rules={[{ required: !formData?.unlimitedResources }]}
              layout="horizontal"
              labelCol={{ span: 12 }}
            >
              <InputNumber min={1} disabled={formData?.unlimitedResources} />
            </Form.Item>
          </Col>
        </Row>

        <Divider
          orientation="left"
          orientationMargin="0"
          style={{ margin: '4px 0 8px' }}
        >
          WebApp Pages Visibility
        </Divider>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              name={['appVisibility', 'stores']}
              label="Stores"
              rules={[{ required: true }]}
              layout="horizontal"
              wrapperCol={{ span: 16 }}
              labelCol={{ span: 8 }}
            >
              <Select options={visibilityOptions} />
            </Form.Item>

            <Form.Item
              label="Campaigns"
              name={['appVisibility', 'campaigns']}
              rules={[{ required: true }]}
              layout="horizontal"
              wrapperCol={{ span: 16 }}
              labelCol={{ span: 8 }}
            >
              <Select options={visibilityOptions} />
            </Form.Item>

            <Form.Item
              label="Missions"
              name={['appVisibility', 'missions']}
              rules={[{ required: true }]}
              layout="horizontal"
              wrapperCol={{ span: 16 }}
              labelCol={{ span: 8 }}
            >
              <Select options={visibilityOptions} />
            </Form.Item>

            <Form.Item
              label="Tax Form"
              name={['appVisibility', 'taxForm']}
              rules={[{ required: true }]}
              layout="horizontal"
              wrapperCol={{ span: 16 }}
              labelCol={{ span: 8 }}
            >
              <Select options={visibilityOptions} />
            </Form.Item>

            <Form.Item
              label="Groups"
              name={['appVisibility', 'groups']}
              rules={[{ required: true }]}
              layout="horizontal"
              wrapperCol={{ span: 16 }}
              labelCol={{ span: 8 }}
            >
              <Select options={visibilityOptions} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Data & Insights"
              name={['appVisibility', 'dataInsights']}
              rules={[{ required: true }]}
              layout="horizontal"
              wrapperCol={{ span: 16 }}
              labelCol={{ span: 8 }}
            >
              <Select options={visibilityOptions} />
            </Form.Item>

            <Form.Item
              label="Submissions"
              name={['appVisibility', 'submissions']}
              rules={[{ required: true }]}
              layout="horizontal"
              wrapperCol={{ span: 16 }}
              labelCol={{ span: 8 }}
            >
              <Select options={visibilityOptions} />
            </Form.Item>

            <Form.Item
              label="Newsfeed"
              name={['appVisibility', 'newsfeed']}
              rules={[{ required: true }]}
              layout="horizontal"
              wrapperCol={{ span: 16 }}
              labelCol={{ span: 8 }}
            >
              <Select options={visibilityOptions} />
            </Form.Item>

            <Form.Item
              label="Forms"
              name={['appVisibility', 'forms']}
              rules={[{ required: true }]}
              layout="horizontal"
              wrapperCol={{ span: 16 }}
              labelCol={{ span: 8 }}
            >
              <Select options={visibilityOptions} />
            </Form.Item>

            <Form.Item
              label="Inbox"
              name={['appVisibility', 'inbox']}
              rules={[{ required: true }]}
              layout="horizontal"
              wrapperCol={{ span: 16 }}
              labelCol={{ span: 8 }}
            >
              <Select options={visibilityOptions} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ModalForm;
